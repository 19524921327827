
import { inject } from '@/inversify';
import { Component, Watch, Vue } from 'vue-property-decorator';

import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import { SCAN_STATUS } from '@/modules/rates/constants';

const MAX_CHECK_REQUESTS = 20;

@Component
export default class RatesHotelTransitionPage extends Vue {
    private scanUpdateInterval: NodeJS.Timer | null = null;

    @inject(RatesServiceS)
    private ratesService!: RatesService;

    @Watch('ratesService.data')
    resetRatesDocument() {
        if (this.scanUpdateInterval !== null || this.ratesService.data === null) {
            return;
        }
        if (this.ratesService.scan.values.status !== SCAN_STATUS.IN_PROGRESS) {
            if (this.scanUpdateInterval !== null) {
                clearInterval(this.scanUpdateInterval);
                this.scanUpdateInterval = null;
            }
            return;
        }
        const checkScanStatusInterval = 60000;
        let checkRequestCounter = 0;
        this.scanUpdateInterval = setInterval(async () => {
            if (checkRequestCounter > MAX_CHECK_REQUESTS) {
                clearInterval(this.scanUpdateInterval!);
                this.scanUpdateInterval = null;
            }
            if (this.scanUpdateInterval === null) {
                return;
            }
            if (
                this.ratesService.data === null || this.ratesService.scan.values.status !== SCAN_STATUS.IN_PROGRESS
            ) {
                clearInterval(this.scanUpdateInterval);
                this.scanUpdateInterval = null;
            }
            if (this.ratesService.data && this.ratesService.scan.values.status === SCAN_STATUS.IN_PROGRESS) {
                const scanStatus = await this.ratesService.checkScanStatus();
                checkRequestCounter += 1;
                if (scanStatus !== SCAN_STATUS.IN_PROGRESS && this.ratesService.scan.values.status === SCAN_STATUS.IN_PROGRESS) {
                    this.ratesService.storeState.loading.reset();
                    clearInterval(this.scanUpdateInterval!);
                    this.scanUpdateInterval = null;
                }
            }
        }, checkScanStatusInterval);
    }

    beforeDestroy() {
        // Temporary solution, to check scan status and reset rates document
        if (this.scanUpdateInterval !== null) {
            clearInterval(this.scanUpdateInterval);
            this.scanUpdateInterval = null;
        }
    }
}
